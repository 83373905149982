import { TimeInterval } from "src/app/api/v1/models";

export function zeroMaskFromTimeInterval(timeInterval?: TimeInterval | null): string {
  const hour = typeof timeInterval?.hour === 'number'
    ? zeroMask(timeInterval.hour, '00')
    : "--";
  const minute = typeof timeInterval?.minute === 'number'
    ? zeroMask(timeInterval.minute, '00')
    : "--";
  return `${hour}:${minute}`
}

export function zeroMask(index: number, mask: string): string {
  return mask.slice(0, -index.toString().length) + index;
}

export function getFixtureTime(time: string, timeZoneOffset = 0) {
  const hours = parseInt(time.split(':')[0], 10);
  const minutes = parseInt(time.split(':')[1], 10);
  const currentDate = new Date();
  let dateChange = 0;

  if(hours - timeZoneOffset >= 24){
    dateChange = 1;
  }
  else if (hours - timeZoneOffset < 0){
    dateChange = -1;
  }

  return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + dateChange, ((hours - timeZoneOffset) + 24) % 24, minutes, 0);
}

export function getTimeZoneOffset(){
  const currentDate = new Date();
  const convertedDate = convertTZ(new Date(), 'Europe/Moscow');
  return Math.round((convertedDate.getTime() - currentDate.getTime()) / 1000 / 60);
}

export function convertTZ(date :string | Date, tzString: string) {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString('en-US', {timeZone: tzString}));
}

export function timeToCurrentTZ(time: string){
  const minuteTime = parseTimeToMinute(time);
  const newTime = minuteTime - getTimeZoneOffset();

  const hours = parseInt((((newTime / 60) + 24) % 24).toString(), 10)
  const minutes = (newTime + 1440) % 1440 - hours * 60;

  return zeroMask(hours, '00') + ':' + zeroMask(minutes, '00');
}

export function parseTimeToMinute(time: string): number{
  const timeArr = time.split(':');
  return parseInt(timeArr[0], 10) * 60 + parseInt(timeArr[1], 10);
}
