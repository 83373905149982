import { Component, inject, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { fromEvent, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvironmentTypes } from 'src/environments/environment-types';
import { CURRENT_BRANCH } from 'src/environments/version';

import { SeoService } from './services/seo.service';
import { ConfigRepository } from './shared/repositories/config.repository';
import { MenuListRepository } from './shared/repositories/menu-list.repository';
import { IS_BROWSER } from './shared/tokens/browser';
import { SeparateTask } from './shared/utils/separate-task';
import { Layout } from './api/v1/models/layout';
import { BackLinkService } from './shared/components/mobile-nav/back-link.service';
import { filter, map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import { LocalstorageService } from './shared/services/localstorage.service';
import { LANG_OPTIONS } from './shared/tokens/langOptions/lang-options';
import { WINDOW } from './shared/tokens/window';
import { CookiesAlertComponent } from './shared/components/cookies-alert/cookies-alert.component';
import { GEOLOCATION } from './shared/tokens/geolocation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [
      CommonModule,
      RouterOutlet,
    ],
})
export class AppComponent implements OnInit {
  constructor(
    private menuListStore: MenuListRepository,
    private router: Router,
    private seoService: SeoService,
    private configRepository: ConfigRepository,
    @Inject(IS_BROWSER) private isBrowser: boolean,
    @Inject(WINDOW) private window: Window,
    @Inject(GEOLOCATION) private geolocation: Geolocation | null,
    private backLink: BackLinkService,
    private swUpdate: SwUpdate,
    private localStorage: LocalstorageService,
  ) {
    if (environment.type !== EnvironmentTypes.dev) {
      this.checkLocalePath();

      if(!this.configRepository.userLocale){
        this.configRepository.defaultLocale$.subscribe(defaultLocale => {
          this.configRepository.setUserLocale(defaultLocale)
        })
      }
    }

    if(this.geolocation){
      this.geolocation.getCurrentPosition(position => {
        console.info('longitude: ', position.coords.longitude, 'latitude', position.coords.latitude)
        this.configRepository.setUserPosition({longitude: position.coords.longitude, latitude: position.coords.latitude});
      })
    }
  }

  layouts = Layout;

  private defaultScrollUrls: string[] = ['/ordering', '/about', '/privacy'];

  @ViewChild('headerView', { read: ViewContainerRef }) headerView: ViewContainerRef;
  @ViewChild('footerView', { read: ViewContainerRef }) footerView: ViewContainerRef;

  @ViewChild('statusMessageView', { read: ViewContainerRef }) statusMessageView: ViewContainerRef;

  @ViewChild('a2hsView', { read: ViewContainerRef }) a2hsView: ViewContainerRef;

  @ViewChild('cookiesAlertView', { read: ViewContainerRef }) cookiesAlertView: ViewContainerRef;

  isHideHeader$: Observable<boolean> = this.router.events.pipe(
    filter(event => (event instanceof NavigationStart)),
    map(event => {
      if (event instanceof NavigationStart) {
        if (event.url === '/' || event.url.includes('/category')) {
          return false;
        }
      }
      return true;
    })
  );

  isIiko$: Observable<boolean | null> =  this.configRepository.isIiko$;

  ngOnInit(): void {
    if(typeof window !== 'undefined') {
      const chunkErrorEvent$: Observable<any> = fromEvent(window, 'chunkLoadError');

      chunkErrorEvent$.subscribe(() => {
        void this.router.navigate(['**']);
        const isHasChunkError = this.localStorage.get('chunkLoadError');

        if (!isHasChunkError){
          this.localStorage.set('chunkLoadError', true);
          this.swUpdate.activateUpdate().then(() => {
            window.location.reload();
          })
          throw new Error('Chunk load error');
        }

        this.localStorage.remove('chunkLoadError');
        throw new Error('Chunk load error after reload');
      })
    }

    this.subscribeToRouter();
    this.fetchMenu();

    this.configRepository.layout$.subscribe(layout => {
      if (layout == Layout.Fresh) {
        void this.loadHeaderFresh();
        void this.loadFooterFresh();
      } else {
        void this.loadHeaderDefault();
        void this.loadFooterDefault();
      }
    })

    void this.loadStatusMessage();
    void this.loadA2hs();

    this.isIiko$.subscribe(isIiko => {
      if (isIiko && !this.localStorage.get('cookiesModal')) {
        void this.loadCookiesAlert();
      }
    })

    if (environment.type === EnvironmentTypes.testing) {
      console.info(`Current commit branch is ${CURRENT_BRANCH}`);
    }
  }

  private checkLocalePath(){
    const langOptions = inject(LANG_OPTIONS)
    const parts = this.window.location.pathname.split('/');

    const pathname = this.window.location.pathname;

    if(parts[1] && !Object.keys(langOptions).includes(parts[1]) && this.configRepository.userLocale) {
      //Редирект, если есть сохраненная локаль и адрес без локали
      this.window.location.href = `${this.window.location.origin}/${this.configRepository.userLocale}${pathname}`;
      return;
    }

    if(this.configRepository.userLocale && parts[1] && this.configRepository.userLocale !== parts[1]){
      //Редирект, если ссылка с другой локалью
      this.window.location.href = `${this.window.location.origin}/${this.configRepository.userLocale}${pathname.slice(3, pathname.length)}`;
      return;
    }

    if(parts[1] && Object.keys(langOptions).includes(parts[1]) && !this.configRepository.userLocale){
      this.configRepository.setUserLocale(parts[1])
    }
  }

  private prevUrl: string;
  private subscribeToRouter(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.prevUrl){
          this.backLink.stackLink(this.prevUrl);
        }
        this.prevUrl = event.url;

        if (~this.defaultScrollUrls.indexOf(event.url)) {
          window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        }
      }

      if (event instanceof NavigationEnd) {
        if (event.url === '/') {
          this.menuListStore.setActiveCatalogUrl();
        }
        this.seoService.setMetaTags(event);
      }
    });
  }

  private fetchMenu(): void {
    if (!this.isBrowser) return;

    this.menuListStore.fetchMenu();
  }

  @SeparateTask()
  async loadHeaderDefault(): Promise<void> {
    this.headerView.clear();
    const {HeaderComponent} = await import('./blocks/header/header.component');
    this.headerView.createComponent(HeaderComponent);
  }

  @SeparateTask()
  async loadHeaderFresh(): Promise<void> {
    this.headerView.clear();
    const {HeaderComponent} = await import('./layout/fresh/components/header/header.component');
    this.headerView.createComponent(HeaderComponent);
  }

  @SeparateTask()
  async loadFooterDefault(): Promise<void> {
    this.footerView.clear();
    const {FooterComponent} = await import('./blocks/footer/footer.component');
    this.footerView.createComponent(FooterComponent);
  }

  @SeparateTask()
  async loadFooterFresh(): Promise<void> {
    this.footerView.clear();
    const {FooterComponent} = await import('./layout/fresh/components/footer/footer.component');
    this.footerView.createComponent(FooterComponent);
  }

  @SeparateTask()
  async loadStatusMessage(): Promise<void> {
    this.statusMessageView.clear();
    const {StatusMessageComponent} = await import('./blocks/status-message/status-message.component');
    this.statusMessageView.createComponent(StatusMessageComponent);
  }

  @SeparateTask()
  async loadA2hs(): Promise<void> {
    this.a2hsView.clear();
    const {A2hsComponent} = await import('./shared/components/a2hs/a2hs.component');
    this.a2hsView.createComponent(A2hsComponent);
  }

  @SeparateTask()
  async loadCookiesAlert(): Promise<void> {
    this.cookiesAlertView.clear();
    const {CookiesAlertComponent} = await import('./shared/components/cookies-alert/cookies-alert.component');
    this.cookiesAlertView.createComponent(CookiesAlertComponent);
  }
}
